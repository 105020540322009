// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import type React from "react";
import type { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

const buttonCn = "bg-[#201111] px-[40px] py-[12px] text-white rounded-[12px]";

const Button: React.FC<PropsWithChildren<{ to?: string; toExternal?: string; noBlank?: boolean }>> = ({ children, to, toExternal, noBlank = false }) => {
  if (toExternal) {
    if (noBlank) {
      return (
        <a href={toExternal} rel="noopener noreferrer">
          <button type="button" className={buttonCn}>
            {children}
          </button>
        </a>
      );
    }

    return (
      <a href={toExternal} target="_blank" rel="noopener noreferrer">
        <button type="button" className={buttonCn}>
          {children}
        </button>
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to}>
        <button type="button" className={buttonCn}>
          {children}
        </button>
      </Link>
    );
  }

  return (
    <button type="button" className={buttonCn}>
      {children}
    </button>
  );
};

export default Button;
