// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import type React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../Common/ScrollToTop";
import Footer from "./Footer2";
import Header from "./Header2";

const MainLayout: React.FC = () => {
  return (
    <main className="flex flex-col min-h-[100vh]">
      <Header />

      <div className="flex-1 pt-[80px] text-white">
        <Outlet />
      </div>

      <Footer />
      <ScrollToTop />
    </main>
  );
};

export default MainLayout;
