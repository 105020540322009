// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import type React from "react";
import type { PropsWithChildren } from "react";
import cn from "../../services/cn";

const Container: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return <div className={cn("mx-auto px-6 container", className)}>{children}</div>;
};

export default Container;
