// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import type React from "react";
import { Suspense, lazy } from "react";

const create =
  (Inner: React.LazyExoticComponent<React.FC>, Loading?: React.ReactNode): React.FC =>
  () => {
    if (Loading) return <Suspense fallback={Loading}>{<Inner />}</Suspense>;
    return <Suspense fallback={<div />}>{<Inner />}</Suspense>;
  };

export const LazyHome = create(lazy(() => import("../../screens/Home/Home")));
export const LazyNotFound = create(lazy(() => import("../../screens/NotFound")));
export const LazyPosts = create(lazy(() => import("../../screens/Posts/Posts")));

export const LazyContact = create(lazy(() => import("../../screens/Home/Contact")));
export const LazyBrand = create(lazy(() => import("../../screens/Home/Brand")));

export const LazyStake = create(lazy(() => import("../../screens/Home/Stake")));

export const LazyDevelopment = create(lazy(() => import("../../screens/Home/Development")));
export const LazyEvents = create(lazy(() => import("../../screens/Home/Events")));
export const LazyServices = create(lazy(() => import("../../screens/Home/Services")));

export const LazyPrivacy = create(lazy(() => import("../../screens/Home/Privacy")));
export const LazyTerms = create(lazy(() => import("../../screens/Home/Terms")));

export const LazyVerified = create(lazy(() => import("../../screens/Home/Verified")));
export const LazyReport = create(lazy(() => import("../../screens/Home/Report")));

export const LazyHero = create(lazy(() => import("../../components/Home/Hero")));
export const LazyMainnet = create(lazy(() => import("../../components/Home/Mainnet")));
export const LazyTestnet = create(lazy(() => import("../../components/Home/Testnet")));
export const LazyDApps = create(lazy(() => import("../../components/Home/DApps")));
export const LazyPartners = create(lazy(() => import("../../components/Home/Partners")));
