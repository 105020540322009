import axios from "axios";
import { getServiceHosts } from "./api";
import { getProfiles } from "./profile/getProfiles";

const generateAuthorization = (token = "") => {
  if (token) return `Bearer ${token}`;

  return "";
};

const generateApi = (token = "") => {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `${generateAuthorization(token)}`,
    },
  });
};

export let api = generateApi();

export const setToken = (token: string) => {
  api = generateApi(token);

  getProfiles(getServices());
};

const generateServices = () => {
  const serviceHosts = getServiceHosts();

  const ethEndpoint = serviceHosts.eth;
  const landingEndpoint = serviceHosts.landing;

  return {
    eth: {
      nonce: `${ethEndpoint}/nonce`,
      verify: `${ethEndpoint}/verify`,
      health: `${ethEndpoint}/health`,
      logout: `${ethEndpoint}/logout`,
    },
    landing: {
      contact: `${landingEndpoint}/contact`,
    },
  };
};

let services = generateServices();

export const createServices = () => {
  services = generateServices();

  return services;
};

export const getServices = () => {
  return services;
};

export type Servcices = typeof services;
