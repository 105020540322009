// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import type React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";

import {
  LazyBrand,
  LazyContact,
  LazyDevelopment,
  LazyEvents,
  LazyHome,
  LazyNotFound,
  LazyPosts,
  LazyPrivacy,
  LazyReport,
  LazyServices,
  LazyStake,
  LazyTerms,
  LazyVerified,
} from "./elements";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<LazyHome />} />

          <Route path="/contact" element={<LazyContact />} />
          <Route path="/brand" element={<LazyBrand />} />

          <Route path="/stake" element={<LazyStake />} />

          <Route path="/development" element={<LazyDevelopment />} />
          <Route path="/events" element={<LazyEvents />} />
          <Route path="/services" element={<LazyServices />} />

          <Route path="/posts" element={<LazyPosts />} />

          <Route path="/privacy" element={<LazyPrivacy />} />
          <Route path="/terms" element={<LazyTerms />} />

          <Route path="/verified" element={<LazyVerified />} />
          <Route path="/report" element={<LazyReport />} />

          <Route path="*" element={<LazyNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
