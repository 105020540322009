// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import { Github, Linkedin, Telegram } from "@styled-icons/boxicons-logos";
import type React from "react";
import { Link } from "react-router-dom";
import { nodeheathActive } from "../../config";
import Button from "../Common/Button";
import Container from "../Common/Container";

const Footer2: React.FC = () => {
  return (
    <footer className="pt-12 mt-16">
      <Container className="bg-[#FFFFFF] text-black rounded-t-[29px] px-[42px]">
        <img src="/images/p10node-512.png" alt="" width={280} className="float-right mt-[-57px] mr-[18px] ml-[15px]" />

        <section className="pt-[29px]">
          <div className="text-[32px] mb-[8px]">p10node</div>
          <div>
            <div className="mb-[2px]">At p10node, we provide node running, proof-of-stake validators, chain services, and product development.</div>
            <div>And stay updated with the latest news in our p10node community dedicated to trust, support, and sustainability.</div>
          </div>
          <div className="mt-[27px]">
            <Button to="/stake">Stake with Us</Button>
          </div>
        </section>

        <section className="py-[48px] overpass-body text-[18px]">
          <div className="flex flex-col lg:flex-row lg:gap-[80px] gap-[30px]">
            <nav>
              <div className="font-bold">Products</div>
              <ul className="flex flex-col gap-[16px] mt-[14px]">
                {nodeheathActive && (
                  <li>
                    <a href="https://nodehealth.p10node.com" rel="noopener noreferrer">
                      NodeHealth
                    </a>
                  </li>
                )}
                <li className="">
                  <a href="https://explorer.p10node.com/" target="_blank" rel="noopener noreferrer">
                    CosScan
                  </a>
                </li>
                <li>
                  <Link to="/posts">Blog & News</Link>
                </li>
              </ul>
            </nav>

            <nav>
              <div className="font-bold">Services</div>
              <ul className="flex flex-col gap-[16px] mt-[14px]">
                <li>
                  <Link to="/development">Product Development</Link>
                </li>
                <li>
                  <Link to="/services">Chain Services</Link>
                </li>
                <li>
                  <Link to="/events">Events</Link>
                </li>
              </ul>
            </nav>

            <nav>
              <div className="font-bold">Community</div>
              <ul className="flex flex-col gap-[16px] mt-[14px]">
                <li className="">
                  <a href="https://t.me/p10nodaemon" target="_blank" rel="noopener noreferrer">
                    Annoucement
                  </a>
                </li>
                <li>
                  <a href="https://t.me/p10node" target="_blank" rel="noopener noreferrer">
                    Telegram Community
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <section className="pb-[20px] overpass-body text-[18px]">
          <div className="mb-2">
            <div className="flex gap-2">
              <a href="https://www.linkedin.com/company/p10node" target="_blank" rel="noopener noreferrer">
                <Linkedin size={32} />
              </a>

              <a href="https://github.com/p10node" target="_blank" rel="noopener noreferrer">
                <Github size={32} />
              </a>

              <a href="https://t.me/p10nodaemon" target="_blank" rel="noopener noreferrer">
                <Telegram size={32} />
              </a>
            </div>
          </div>
          <div className="mt-2 flex flex-col gap-2 lg:flex-row lg:justify-between">
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-6">
              <div>
                <Link to="/contact">Contact</Link>
              </div>
              <div>
                <Link to="/brand">Brand Kit</Link>
              </div>
              <div>
                <a href="http://docs.p10node.com" rel="noopener noreferrer">
                  Documents
                </a>
              </div>
              <div>
                <Link to="/verified">Verified Accounts</Link>
              </div>
              <div>
                <Link to="/report">Feedback & Bug Report</Link>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-5">
              <div>
                <Link to="/privacy">Privacy</Link>
              </div>
              <div>
                <Link to="/terms">Terms</Link>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </footer>
  );
};

export default Footer2;
